
import { defineComponent } from "vue";
import { mapStores } from "pinia";
import FileUpload from "@/components/FileUpload.vue";
import Analytics from "@/components/Analytics.vue";
import { useMainStore } from "@/store";

export default defineComponent({
  name: "Home",
  components: {
    FileUpload,
    Analytics,
  },
  data() {
    return {
      selectedName: null,
      names: [],
    };
  },
  methods: {
    handleUploadFinished(event) {
      this.names = event.names;
      if (this.names) {
        this.selectedName = this.names[0];
      }
    },
    setName() {
      this.mainStore.myName = this.selectedName;
    },
  },
  computed: mapStores(useMainStore),
});
